import React from "react";
import images from "../../images/images";
import { NavLink } from "react-router-dom"; // Ensure the correct path to images // Optional: For additional custom styles if needed
import Form from "../../CommonCompounds/Form/Form";
import Itinerary from "../../CommonCompounds/Itinerary/Itinerary";



function Index() {
  return (
    <>
 
      <section className="Section-time">
        <div className="container py-5 px-4 ">
          <div className="row justify-content-around">
            {/* Experience */}
            <div className="col-md-2 col-6 d-flex align-items-center py-4">
              <img src={images.reward} alt="Experience Icon" className="icon" />
              <div className="ms-3">
                <h5>
                  <span className="count py-2">15+</span> <br /> EXPERIENCE
                </h5>
              </div>
            </div>

            {/* Destinations */}
            <div className="col-md-2 col-6 d-flex align-items-center py-4">
              <img
                src={images.Group}
                alt="Destinations Icon"
                className="icon"
              />
              <div className="ms-3">
                <h5>
                  <span className="count py-2">20+</span> <br /> DESTINATIONS
                </h5>
              </div>
            </div>

            {/* Packages */}
            <div className="col-md-2 col-6 d-flex align-items-center py-4">
              <img src={images.luggage} alt="Packages Icon" className="icon" />
              <div className="ms-3">
                <h5>
                  <span className="count py-2">38+</span> <br /> PACKAGES
                </h5>
              </div>
            </div>

            {/* Bookings */}
            <div className="col-md-2 col-6 d-flex align-items-center py-4">
              <img src={images.booking} alt="Bookings Icon" className="icon" />
              <div className="ms-3">
                <h5>
                  <span className="count py-2">500+</span> <br /> BOOKINGS
                </h5>
              </div>
            </div>

            {/* Happy Clients */}
            <div className="col-md-2 col-12 d-flex align-items-center py-4">
              <img
                src={images.customer}
                alt="Happy Clients Icon"
                className="icon"
              />
              <div className="ms-3">
                <h5>
                  <span className="count py-2">800+</span> <br /> HAPPY CLIENTS
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container my-80">
          <div className="row">
            {/* Package Overview Section */}
            <div className="col-lg-8 col-md-12">
              <div className="px-3 py-3">
                <h2>
                  Chikmagalur 2N3D Package{" "}
                  <span className="text-warning">Overview</span>
                </h2>
                <p className="mt-4">
                Escape to the serene beauty of Chikamagalur with Bookonetour’s 2 Nights 3 Days couple package. 
                This romantic getaway offers luxurious accommodations surrounded by lush coffee plantations, 
                providing the perfect setting for couples to relax and reconnect. Enjoy intimate dining experiences 
                featuring local Chikamagalur cuisine, and immerse yourselves in the region's natural splendor.
<br /> <br/>
                Your adventure includes guided explorations of breathtaking attractions such as Mullayanagiri Peak, 
                Karnataka’s highest point, and the stunning Hebbe Falls. Discover the charm of coffee plantations with 
                insightful tours that highlight the coffee-making process. This package perfectly blends adventure and 
                tranquility, allowing you to create unforgettable memories in this enchanting
                </p>

                {/* Package Inclusions */}
                <h5 className="mt-5">
                  <span className="point-package px-1">
                    <img src={images.point} alt="" className="img-fluid" />
                  </span>
                  Package Inclusion
                </h5>
                <div className="row text-center mt-4 justify-content-around">
                  <div className="col-6 col-md-4 col-lg-2">
                    <img
                      src={images.package1}
                      alt="Private Cab"
                      className="mb-2 img-"
                    />
                    <p className="download-text">SIGHTSEEING</p>
                  </div>
                  <div className="col-6 col-md-4 col-lg-2">
                    <img
                      src={images.package2}
                      alt="Sightseeing"
                      className="mb-2 img-"
                    />
                    <p className="download-text">PRIVATE CAB</p>
                  </div>
                  <div className="col-6 col-md-4 col-lg-2">
                    <img
                      src={images.package3}
                      alt="Hotel Stay"
                      className="mb-2 img-"
                    />
                    <p className="download-text">TOUR GUIDE</p>
                  </div>
                  <div className="col-6 col-md-4 col-lg-2">
                    <img
                      src={images.package4}
                      alt="Breakfast"
                      className="mb-2 img-"
                    />
                    <p className="download-text">BREAKFAST</p>
                  </div>
                  <div className="col-6 col-md-4 col-lg-2">
                    <img
                      src={images.package5}
                      alt="Tour Guide"
                      className="mb-2 img-"
                    />
                    <p className="download-text">HOTEL STAY</p>
                  </div>
                </div>

                {/* Pickup and Drop Service Points */}
                <h5 className="mt-5">
                  <span className="point-package px-1">
                    <img src={images.point} alt="" className="img-fluid" />
                  </span>
                  Pickup and Drop Service Point
                  {/* <span className="text-warning text-decoration-none px-2">
                    (Download Itinerary)
                  </span> */}
                </h5>
                <div className="row px-2 mt-4">
  <div className="col-12 col-md-4">
    <p className="download-pdf">
        1. CHIKMAGALUR
    </p>
  </div>

  <div className="col-12 col-md-4">
    <p className="download-pdf">
        2. MYSORE
    </p>
  </div>

  <div className="col-12 col-md-4">
    <p className="download-pdf">
    3. MANGALORE
    </p>
  </div>
</div>

              </div>
            </div>
            {/* Quote Form Section */}
            <div className="col-md-12 col-lg-4">
              <Form />
            </div>
          </div>
        </div>
      </section>

      <section className="mx-4">
        <div className="container mb-4">
          <div className="row package-border">

            <div className="col-12 col-md-3 text-center custom-border-end mb-4 mb-md-0 py-2">
              <p className="mb-1 package-places">Chikmagalur Pickup and Drop</p>
              <img
                    src={images.highprice001}
                    alt="Original Price"
                    className="img-fluid"
                  />
              <h2 className="mb-2 pt-3 Packages-prices">Rs. 7499/- 
              <p className="mb-0 person-contant">per person</p>
              </h2>
            
              <a
                href="https://chikmagalur2nights3days.bookonetour.in/Pdfs/2N3D_chikmangalur.pdf"
                className="download-pdffile"
                download
                target="_blank"
              >
                Download Itenary
                <span className="px-1">
                  <img
                    src={images.packagedownload}
                    alt="Download Bangalore Itinerary"
                    className="img-fluid"
                  />
                </span>
              </a>
            </div>

            <div className="col-12 col-md-3 text-center custom-border-end mb-4 mb-md-0 py-2">
              <p className="mb-1 package-places">Mysore Pickup and Drop</p>
              <img
                    src={images.highprice002}
                    alt="Original Price"
                    className="img-fluid"
                  />
              <h2 className="mb-2 pt-3 Packages-prices">Rs. 8999/-
              <p className="mb-0 person-contant">per person</p>
              </h2>
              
              <a
                href="https://chikmagalur2nights3days.bookonetour.in/Pdfs/2N3D_mysore_chikmangalur.pdf"
                className="download-pdffile"
                download
                target="_blank"
              >
                Download Itenary
                <span className="px-1">
                  <img
                    src={images.packagedownload}
                    alt="Download Bangalore Itinerary"
                    className="img-fluid"
                  />
                </span>
              </a>
            </div>
            
            <div className="col-12 col-md-3 text-center custom-border-end mb-4 mb-md-0 py-2">
              <p className="mb-1 package-places">Mangalore Pickup and Drop</p>
              <img
                    src={images.highprice003}
                    alt="Original Price"
                    className="img-fluid"
                  />
              <h2 className=" pt-3 Packages-prices">Rs. 10,999/- 
              <p className="mb-0 person-contant">per person</p>
              </h2>
            
              <a
                href="https://chikmagalur2nights3days.bookonetour.in/Pdfs/2N3D_mangalore_chikmangalur.pdf"
                className="download-pdffile"
                download
                target="_blank"
              >
                Download Itenary
                <span className="px-1">
                  <img
                    src={images.packagedownload}
                    alt="Download Bangalore Itinerary"
                    className="img-fluid"
                  />
                </span>
              </a>
            </div>
            <div className="col-12 col-md-3 text-center py-2">
              <p className="mb-1 package-places">GET FLAT</p>
              <h3 className="text-offer mb-1">30% OFF</h3>
              <p className="mb-0 person-contant">ON ALL OTHER PACKAGES</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <Itinerary />
      </section>

      <section className="py-4 my-100 green-backgroundImage">
        <div className="container px-4">
          <div className="container py-5">
            <div className="row justify-content-around">
              {/* Package Inclusion */}
              <div className="col-md-5 bg-light p-4 rounded-4 my-4">
                <h3 className="text-center text-purple mb-4 mt-4">
                  PACKAGE INCLUSION
                </h3>
                <ul className="list-unstyled px-4 ">
                  <ul className="list-unstyled">
                    <li className="custom-list-item">
                      <span className="Open-items">✔</span> 2N Chikmagalur Hotel with Breakfast
                    </li>
                    <li className="custom-list-item">
                      <span className="Open-items ">✔</span> Accommodation on Double or Triple sharing all the hotels.
                    </li>
                    <li className="custom-list-item">
                      <span className="Open-items">✔</span> Pickup and Drop by Private Cab.
                    </li>
                    <li className="custom-list-item">
                      <span className="Open-items">✔</span> A/C Cab for all transfers and sightseeing exactly as per the itinerary.
                    </li>
                    <li className="custom-list-item">
                      <span className="Open-items">✔</span> Toll, Parking, driver’s Bata, road tax & fuel charges
                    </li>
      
                  </ul>
                </ul>
              </div>

              {/* Package Exclusion */}
              <div className="col-md-5 bg-light p-4 rounded-4 my-4">
                <h3 className="text-center text-purple mb-4 mt-4">
                  PACKAGE EXCLUSION
                </h3>
                <ul className="list-unstyled px-4">
  <li className="custom-list-item">
    <span className="Close-items">✘</span> Meals not mentioned above.
  </li>
  <li className="custom-list-item">
    <span className="Close-items">✘</span> Entry Fees to Monuments and Palaces.
  </li>
  <li className="custom-list-item">
    <span className="Close-items">✘</span> Anything Not Mentioned In The Inclusions.
  </li>
  <li className="custom-list-item">
    <span className="Close-items">✘</span> Tickets, tips, guided and Entrance Fees/ Charges.
  </li>
  <li className="custom-list-item">
    <span className="Close-items">✘</span> Porterage at Hotels and Airports, Tips, Insurance, Laundry, Liquors, Wine, Etc.
  </li>
  <li className="custom-list-item">
    <span className="Close-items">✘</span> Any Increase in Taxes or Fuel Leading to an Increase In Surface Transport.
  </li>
  <li className="custom-list-item">
    <span className="Close-items">✘</span> Any Cost Arising Due To Natural Calamities Like Landslides, Roadblocks, Etc.
  </li>
  <li className="custom-list-item">
    <span className="Close-items">✘</span> All items of personal nature.
  </li>
</ul>

              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Index;
