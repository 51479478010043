import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import router from './router/router.js';
import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';

const staging = '/';



const App = () => {
  return (
    <>
      <Router basename={staging}>
        <Routes>
          {router.map((route) => {
            return (
              <Route
                key={route.name}
                path={route.path}
                exact={route.exact}
                name={route.name}
                element={<route.element />}
              />
            );
          })}
        </Routes>
      </Router>
    </>
  );
};

export default App;