
import HomePage from "../Pages/homepage/home_page";


const router = [

    
    { path: "/", exact: true, name: "Homepage", element:HomePage  },
     
    

]


export default router;